<template>
  <div>
    <el-form ref="form" label-width="120px">
      <el-form-item label="任务名称">
        {{node.name}}
      </el-form-item>
      <el-form-item label="状态">
        <el-tag v-if="node.enabled === 1" type="success">启用</el-tag>
        <el-tag v-else type="info">禁用</el-tag>
      </el-form-item>
      <el-form-item label="运行状态">
        <el-tag v-if="node.last_record_status === 1" type="success">运行中</el-tag>
        <el-tag v-else type="info">已停止</el-tag>
        <el-button style="margin-left: 10px;" @click="start(node, node.last_record_status === 1 ? true : false)" type="text" size="small">{{node.last_record_status === 1 ? '停止' : '启动'}}</el-button>
      </el-form-item>
      <el-form-item label="创建时间">
        {{node.create_time}}
      </el-form-item>
      <el-form-item label="更新时间">
        {{node.update_time}}
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// import _ from 'lodash';
export default {
  props: { node: Object },
  data() {
    return {
    }
  },

  methods: {
    show() {
      this.refresh();
    },
    async refresh() {
      this.$emit('refresh')
    },
    async start(obj, stop = false) {
      try {
        await this.$api.execute(stop ? 'task.stop' : 'task.start', obj.id);
        this.refresh()
        this.$message.success('操作成功')
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>